import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Footer from '@components/Footer/Footer';
import HeroIcon from '@atoms/HeroIcon/HeroIcon';
import Callout from '@atoms/Callout/Callout';
import Table from '@components/Table/Table';
import TableItem from '@components/Table/TableItem';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import Seo from '@components/seo';
import Button from '@atoms/Button/Button';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import { FaNotesMedical } from 'react-icons/fa';
import Module from '@atoms/Module/Module';
import HeaderMinimal from '@components/HeaderMinimal/HeaderMinimal';
import ModuleGroup from '@atoms/ModuleGroup';
import { Grid } from '@material-ui/core';
import { useInsuranceCheckerData } from '@hooks';
import { withLocale } from '@hocs';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: {
                eq: "insurance-checker/header-stack-of-paper-mobile.jpg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: {
                eq: "insurance-checker/header-stack-of-paper-desktop.jpg"
            }
        ) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

const InsuranceCheckerPage = ({ data: static_images }) => {
    const { t } = useTranslation();
    const { insuranceData } = useInsuranceCheckerData();
    // Header mobile
    const static_images_sources = [
        static_images.headerMobileImage.childImageSharp.fluid,
        {
            ...static_images.headerDesktopImage.childImageSharp.fluid,
            media: `(min-width: 750px)`,
        },
    ];

    return (
        <Layout>
            <Seo
                title={t('insurance.checker.page.seo.title')}
                description={t('insurance.checker.page.seo.description')}
            />
            <div className="global_theme-green">
                <HeaderNav theme="white" />
                <Section theme="light">
                    <HeaderMinimal
                        theme="light"
                        spacing="m"
                        title={t('insurance.checker.page.header.title')}
                        btnPrimary={
                            <AnchorLink offset={() => 40} href="#mehr">
                                <Button
                                    variant="primary"
                                    theme="white"
                                    label={t('insurance.checker.page.main.cta')}
                                    size="l"
                                />
                            </AnchorLink>
                        }
                        imageSrc={static_images_sources}
                    />

                    <div id="mehr">
                        <Section container="large" spacing="m" theme="light">
                            {insuranceData.map((insurance) => {
                                const packagesSortedByMaxAmount = insurance.packages
                                    .map((item) => item.maxAmount)
                                    .sort((a, b) => b - a);
                                const maxAmount = packagesSortedByMaxAmount[0];
                                return (
                                    <Section
                                        spacingBottom="m"
                                        key={insurance.id}
                                    >
                                        <ModuleGroup
                                            title={insurance.name}
                                            subtitle={
                                                maxAmount
                                                    ? t(
                                                          'insurance.checker.max.covered',
                                                          {
                                                              value: maxAmount,
                                                          }
                                                      )
                                                    : ''
                                            }
                                        >
                                            <Grid container spacing={4}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={8}
                                                >
                                                    <Module>
                                                        <Table variant="block">
                                                            {insurance.packages.map(
                                                                (p: any) => (
                                                                    <TableItem
                                                                        key={
                                                                            p.id
                                                                        }
                                                                    >
                                                                        <strong>
                                                                            {
                                                                                p.name
                                                                            }
                                                                        </strong>
                                                                        <span>
                                                                            {
                                                                                p.description
                                                                            }
                                                                        </span>
                                                                    </TableItem>
                                                                )
                                                            )}
                                                        </Table>
                                                    </Module>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={4}
                                                >
                                                    {insurance.requireDoctorNote ? (
                                                        <Section spacingBottom="s">
                                                            <Module>
                                                                <Callout
                                                                    variant="contained"
                                                                    direction="start"
                                                                    icon={
                                                                        <HeroIcon
                                                                            size="xxs"
                                                                            theme="dark"
                                                                        >
                                                                            <FaNotesMedical />
                                                                        </HeroIcon>
                                                                    }
                                                                    title={t(
                                                                        'insurance.checker.contact.doctor.note.neeeded.title'
                                                                    )}
                                                                    text={t(
                                                                        'insurance.checker.contact.doctor.note.neeeded.subtitle'
                                                                    )}
                                                                />
                                                            </Module>
                                                        </Section>
                                                    ) : (
                                                        <Section spacingBottom="s">
                                                            <Module>
                                                                <Callout
                                                                    variant="contained"
                                                                    direction="start"
                                                                    icon={
                                                                        <HeroIcon
                                                                            size="xxs"
                                                                            theme="dark"
                                                                            inactive
                                                                        >
                                                                            <FaNotesMedical />
                                                                        </HeroIcon>
                                                                    }
                                                                    title={t(
                                                                        'insurance.checker.contact.doctor.note.not.neeeded.title'
                                                                    )}
                                                                    text={t(
                                                                        'insurance.checker.contact.doctor.note.not.neeeded.subtitle'
                                                                    )}
                                                                />
                                                            </Module>
                                                        </Section>
                                                    )}
                                                    {insurance.specialNote && (
                                                        <Module>
                                                            <Title size="m">
                                                                {t(
                                                                    'generic.more.info'
                                                                )}
                                                            </Title>
                                                            <Text
                                                                tag="div"
                                                                size="s"
                                                            >
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            insurance.specialNote,
                                                                    }}
                                                                ></div>
                                                            </Text>
                                                        </Module>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </ModuleGroup>
                                        <Section spacingTop="s">
                                            <Title size="s">
                                                {t(
                                                    'insurance.checker.share.new.info'
                                                )}{' '}
                                                <a
                                                    className="emphasis"
                                                    href="mailto:hey@aepsy.com"
                                                >
                                                    hey@aepsy.com
                                                </a>
                                            </Title>
                                        </Section>
                                    </Section>
                                );
                            })}
                        </Section>
                    </div>
                </Section>

                <Footer />
            </div>
        </Layout>
    );
};

export default withLocale(InsuranceCheckerPage);
