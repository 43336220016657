import React from 'react';
import Text from '../Text/Text';
import Title from '../Title/Title';
import './Callout.scss';

interface Props {
    icon?: React.ReactNode;
    iconSize?: 'm' | 'l';
    border?: 'm' | 'l' | 'giant';
    padding?: 'm' | 'l' | 'xl';
    variant?: 'outlined' | 'contained';
    theme?: 'default' | 'purple';
    direction?: 'start' | 'center' | 'end';
    size?: 'm' | 'ml';
    title?: string | React.ReactNode;
    text?: string | React.ReactNode;
    children?: React.ReactNode;
}

const Callout: React.FC<Props> = ({
    icon,
    iconSize = 'm',
    title,
    text,
    variant = 'outlined',
    border = 'm',
    padding = 'm',
    size = 'm',
    theme = 'default',
    direction = 'center',
    children,
}) => {
    const className = [
        'Callout',
        `variant-${variant}`,
        `border-${border}`,
        `padding-${padding}`,
        `theme-${theme}`,
        `direction-${direction}`,
    ]
        .join(' ')
        .replace(/\s{2,}/g, ' ');

    const iconClassName = [
        'Callout--icon',
        iconSize !== undefined ? 'iconSize-' + iconSize : '',
    ]
        .join(' ')
        .replace(/\s{2,}/g, ' ');

    function renderIcon() {
        return <span className={iconClassName}>{icon}</span>;
    }

    const getSize = () => {
        if (size === 'ml') {
            return {
                titleSize: 'ml',
                textSize: 'm',
            };
        }
        return {
            titleSize: 'm',
            textSize: 's',
        };
    };

    const { titleSize, textSize } = getSize();

    function renderTitle() {
        return (
            <Title size={titleSize} align="left" noMargin>
                {title}
            </Title>
        );
    }

    function renderText() {
        return (
            <Text size={textSize} align="left" noMargin>
                {text}
            </Text>
        );
    }

    return (
        <div className={className}>
            {icon && <div>{renderIcon()}</div>}
            <div className="Callout--content">
                {title && renderTitle()}
                {text && renderText()}
                {children && children}
            </div>
        </div>
    );
};

export default Callout;
